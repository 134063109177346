import tw, { css, styled } from 'twin.macro'

import { Form, Field } from 'formik'
import { CardElement } from '@stripe/react-stripe-js'
import { ComboboxInput } from '@reach/combobox'
import { MenuButton } from '@reach/menu-button'

export const ContentSeparator = styled.div`
	${tw`flex flex-col lg:flex-row items-start md:px-10 lg:px-0`}
	> div:first-of-type {
		img {
			${tw`m-auto`}
		}
		${tw`mb-6 lg:mb-0`}
	}
	> div {
		${tw`w-full lg:w-auto`}
		${({ itemPadding }) => (itemPadding ? tw`px-0 lg:px-8` : 0)};
	}
`
export const Heading1 = tw.h3`font-display text-2xl font-bold`
export const Heading2 = tw.h3`font-sans text-lg font-bold`
export const StyledForm = styled(Form)`
	${tw`my-4 w-full`}
	max-width: 34rem;
`
export const FieldRow = styled.div`
	${tw`flex flex-wrap -mx-3`}
`
export const FieldWrap = styled.div`
	${tw`w-full px-3 mb-3 md:mb-0`}
	${({ width }) => (width === '1/2' ? tw`md:w-1/2` : '')}
	${({ width }) => (width === '1/3' ? tw`md:w-1/3` : '')}
	${({ width }) => (width === '1/4' ? tw`md:w-1/4` : '')}
	${({ width }) => (width === '1/5' ? tw`md:w-1/5` : '')}
`
export const StyledComboboxInput = styled(ComboboxInput)`
${tw`flex appearance-none w-full py-2 px-4 mb-3`}
${tw`text-gray-700 leading-tight`}
${tw`bg-white`}
${tw`focus:shadow-outline focus:outline-none`}
${tw`border-2 border-black rounded`}
	&.valid {
		${tw`border-green-500`}
		&::placeholder {
			${tw`text-green-500`}
		}
		&:focus {
			box-shadow: 0 0 0 3px rgba(39, 174, 96, 0.5);
		}
	}
	&.invalid {
		${tw`border-red-500`}
		&::placeholder {
			${tw`text-red-500`}
		}
		&:focus {
			box-shadow: 0 0 0 3px rgba(246, 85, 34, 0.5);
		}
	}
	&::placeholder {
			${tw`text-gray-600`}
	}
	&:focus {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.25);
		${tw`rounded-b-none rounded-t-md`}
	}
`
export const Input = styled(Field)`
		${tw`flex appearance-none w-full py-2 px-4 mb-3`}
		${tw`text-gray-700 leading-tight`}
		${tw`bg-white`}
		${tw`focus:shadow-outline focus:outline-none`}
		${tw`border-2 border-black rounded`}
		${({ valid }) => (valid ? tw`border-green-500` : ``)}
		${({ invalid }) => (invalid ? tw`border-red-500` : ``)}
		${({ minimalist }) => (minimalist ? tw`py-1 px-2` : undefined)}
		&::placeholder {
			${tw`text-gray-600`}
			${({ valid }) => (valid ? tw`text-green-500` : ``)}
			${({ invalid }) => (invalid ? tw`text-red-500` : ``)}
		}
		&[type="checkbox"] {
			${tw`ml-2`}
		}
		&[readonly] {
			${tw`bg-gray-500 border-gray-500 rounded`}
		}
		&:focus {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.25);
			box-shadow: ${({ invalid }) =>
				invalid ? `0 0 0 3px rgba(246, 85, 34, 0.5)` : ``};
			box-shadow: ${({ valid }) => (valid ? `0 0 0 3px rgba(39, 174, 96, 0.5)` : ``)};
		}
`
export const SelectWrap = styled.div`
	${tw`relative`}
`
export const Select = styled(Field)`
	${({ invalid }) => (invalid ? tw`text-gray-500` : tw`text-gray-700`)}
	${tw`flex appearance-none w-full py-2 px-4 pr-8 mb-3`}
	${tw`leading-tight`}
	${tw`bg-white`}
	${tw`focus:shadow-outline focus:outline-none`}
	${tw`border-2 border-black rounded`}
	${({ valid }) => (valid ? tw`border-green-500` : ``)}
	${({ invalid }) => (invalid ? tw`border-red-500` : ``)}
	${({ minimalist }) => (minimalist ? tw`py-1 px-2` : ``)}
	&:focus {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.25);
		box-shadow: ${({ valid }) => (valid ? `0 0 0 3px rgba(39, 174, 96, 0.5)` : ``)};
		box-shadow: ${({ invalid }) =>
			invalid ? `0 0 0 3px rgba(246, 85, 34, 0.5)` : ``};
	}
	option {
		${tw`text-gray-700`}
		&:first-of-type {
			${tw`text-gray-600`}
			${({ valid }) => (valid ? tw`text-green-500` : ``)}
			${({ invalid }) => (invalid ? tw`text-red-500` : ``)}
		}
	}
`
export const SelectButton = styled(MenuButton)`
	${({ invalid }) => (invalid ? tw`text-gray-500` : tw`text-gray-700`)}
	${({ disabled }) =>
		disabled
			? tw`text-gray-500 bg-gray-400 cursor-not-allowed`
			: tw`text-gray-700`}
	${tw`flex items-center appearance-none w-full py-2 px-4 mb-3`}
	${tw`leading-tight`}
	${tw`bg-white`}
	${tw`focus:shadow-outline focus:outline-none`}
	${tw`border-2 border-black rounded`}
	${({ valid }) => (valid ? tw`border-green-500` : ``)}
	${({ invalid }) => (invalid ? tw`border-red-500` : ``)}
	${({ minimalist }) => (minimalist ? tw`py-1 px-2` : ``)}
	&:focus {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.25);
		box-shadow: ${({ valid }) => (valid ? `0 0 0 3px rgba(39, 174, 96, 0.5)` : ``)};
		box-shadow: ${({ invalid }) =>
			invalid ? `0 0 0 3px rgba(246, 85, 34, 0.5)` : ``};
	}
	>.tag {
		${tw`text-xs font-sans bg-gray-800 text-white border-0 rounded-full ml-2 py-px px-2`}
		line-height: 1rem;
		overflow: hidden;
		height: 18px;
	}
`
export const Textarea = styled(Field)`
${tw`flex appearance-none w-full py-2 px-4 mb-3`}
${tw`text-gray-700 leading-tight`}
${tw`bg-white`}
${tw`focus:shadow-outline focus:outline-none`}
${tw`border-2 border-black rounded`}
${({ valid }) => (valid ? tw`border-green-500` : ``)}
${({ invalid }) => (invalid ? tw`border-red-500` : ``)}
&::placeholder {
	${tw`text-gray-600`}
	${({ valid }) => (valid ? tw`text-green-500` : ``)}
	${({ invalid }) => (invalid ? tw`text-red-500` : ``)}
}
&:focus {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.25);
	box-shadow: ${({ invalid }) =>
		invalid ? `0 0 0 3px rgba(246, 85, 34, 0.5)` : ``};
	box-shadow: ${({ valid }) => (valid ? `0 0 0 3px rgba(39, 174, 96, 0.5)` : ``)};
}`
export const StyledCheckBox = styled(Field)``
export const Card = styled.div`
 #card {
		${tw`appearance-none w-full py-2! px-4! mb-3 mt-1`}
		${tw`text-gray-700 leading-tight`}
		${tw`bg-white`}
		${tw`focus:shadow-outline focus:outline-none`}
		${tw`border-2 border-black rounded`}
		${({ valid }) => (valid ? tw`border-green-500` : ``)}
		${({ invalid }) => (invalid ? tw`border-red-500` : ``)}
		${({ minimalist }) => (minimalist ? tw`py-1 px-2` : ``)}
		&::placeholder {
			${tw`text-gray-500`}
			${({ valid }) => (valid ? tw`text-green-500` : ``)}
			${({ invalid }) => (invalid ? tw`text-red-500` : ``)}
		}
		&:focus {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.25);
			box-shadow: ${({ invalid }) =>
				invalid ? `0 0 0 3px rgba(246, 85, 34, 0.5)` : ``};
			box-shadow: ${({ valid }) => (valid ? `0 0 0 3px rgba(39, 174, 96, 0.5)` : ``)};
		}
 }
`
export const SelectIcon = styled.div`
	${tw`pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700`}
	>svg {
		${tw`fill-current h-4 w-4`}
	}
`
export const Helper = styled.pre`
	${tw`text-red-500 text-xs font-mono bg-dolphin-gray pr-2 pl-3 py-3 -mt-2 mb-3 border-0 rounded`}
	&::first-letter {
		${tw`text-white`}
		initial-letter: 1;
	}
`
export const FieldSet = styled.fieldset`
	${tw`w-full px-3 border-black border-2 rounded p-2 bg-white mb-3`}
	&.half {
		${tw`w-full md:w-1/2`}
	}
	${({ errors, touched }) => errors && touched && tw`border-red-500`}
	${({ touched, errors }) => touched && !errors && tw`border-green-500`}
	legend {
		${({ errors, touched }) => errors && touched && tw`border-red-500`}
		${({ touched, errors }) => touched && !errors && tw`border-green-500`}
	}
	label {
		${tw`text-base font-normal text-gray-800 bg-transparent`}
	}
	.checkbox-container {
		${tw`mr-2`}
	}
`
export const Legend = styled.legend`
	${tw`bg-white border-2 border-black rounded px-2 py-1 uppercase tracking-wide text-xs text-base text-gray-700`}
`
export const Label = styled.label`
	${tw`flex items-center uppercase tracking-wide text-xs font-normal text-gray-700`}
`
export const Required = tw.span`text-red-500 mr-auto ml-1`
export const StyledError = styled.p`
	${tw`text-red-500 text-xs font-mono bg-dolphin-gray pr-2 pl-3 py-2 -mt-2 mb-3 border-0 rounded`}
	&::first-letter {
		${tw`text-white`}
		initial-letter: 1;
	}
`
export const StyledHelp = styled.p`
	${tw`text-gray-700 text-xs font-mono bg-gray-400 pr-2 pl-3 py-2 -mt-2 mb-3 border-0 rounded`}
	&::first-letter {
		${tw`text-black`}
		initial-letter: 99;
	}
`
export const ButtonRow = styled.div`
	${tw`flex justify-center px-3 mt-3 md:mb-0`}
`
export const Button = tw.button`shadow bg-yellow-500 hover:bg-yellow-400 focus:shadow-outline focus:outline-none text-black hover:text-white font-normal font-sans py-2 px-4 rounded flex items-center justify-center`
export const HomeButtonRow = styled(ButtonRow)`
	${tw`px-3`}
`
export const HomeButton = styled(Button)`
	${tw`mx-auto bg-yellow-500 hover:bg-yellow-400 text-black hover:text-white`}
`
export const Reminder = styled.p`
	${tw`font-display px-3 text-black mb-3`}

	> a {
		${tw`underline hover:no-underline font-bold`}
	}
`